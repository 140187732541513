export type CommuInfo = {
    commuName: string;
    province:string;
    chiefName: string;
    chiefPhoneNumber: string;
    wkt1: number;
    wkt2: number;
    googleMapURL: string;
    savingPerMonth: number;
    fieldType: {
        no1: string,
        no2: string,
        no3: string,
        no4: string,
    }
    ownerType: {
        no1: string,
        no2: string,
        no3: string,
        no4: string,
    }
};

export type StatusInfo = {
    movingUp: {
        holding: {
            understanding: {
                suggest: false,
                process: false,
                thinking: false,
                connectWithDepartment: false,
            },
            commuStaff: {
                contacts: false,
                processStructure: false,
                policy: false,
                fund: false,
            },
            survey: {
                houseHoldSurvey: false,
                googleForm: false,
                googleMap: false,
                appLing: false,
                walkingMap: false,
            },
            savingGroups: {
                accountDoc: false,
                rules: false,
                goodProcessAccount: false,
            },
        },
        customize: {
            _2or3Months: {
                certOfSuffered: {
                    tableOfbeneficiary: false,
                    rulesOfCert: false,
                },
                summarizeOfSolutions: {
                    commuInfo: false
                },
                meetingCommu: {
                    meeting: false
                },
            },
            dependOnLand: {
                searchinLandForDevelop: {
                    mapOfLand: false
                },
                designCommuMap: {
                    landInfo: false,
                    titleDeed: false,
                    houseHoldInfo: false,
                    topography: false,
                },
                lendingOrSavingPlan: {
                    incomeAndExpenses: false,
                    housePlan: false,
                    housePrice: false,
                    savingPlan: false,
                },
            }
        },
        preparation: {
            coopHolding: {
                coopDocuments: false,
                coopWorkingProcess: false
            },
            buldingOrProcessingPlan: {
                commuMap: false,
                estimateTime: false
            },
            presentProject: {
                projectProposal: false,
                tableOfResponsiblePerson: false
            },
        },
    },
    moving: {
        following: {
            _1Month: {
                buyingIfGovLand: {
                    license: false
                },
            },
            _15daysTo1Month: {
                buildingAllowance: {
                    licenses: false
                }
            },
            orderingLang: {
                orderingBudget: {
                    orderForm: false,
                    housePlan: false,
                    summarizeMeeting: false,
                },
                findBuilder: {
                    findBuilder: false
                }
            },
            projectDurations: {
                building: {
                    building: false,
                },
                inspectSite: {
                    inspectForm: false,
                    buildingContract: false
                },
                approveProject: {
                    approvingForm: false
                },
                questionAndAnswer: {
                    questionForm: false,
                    questionProjectInfo: false
                }
            }
        },
        improvement: {
            durationsAndRules: {
                moreThanHouse: {
                    tutorialDocument: false,
                    basicInfo: false,
                }
            }
        }
    }
}

export const defaultCommuInfo: CommuInfo = {
    commuName: "",
    province: "",
    chiefName: "",
    chiefPhoneNumber: "",
    wkt1: 0,
    wkt2: 0,
    googleMapURL: "",
    savingPerMonth: 0,
    fieldType: {
        no1: "",
        no2: "",
        no3: "",
        no4: "",
    },
    ownerType: {
        no1: "",
        no2: "",
        no3: "",
        no4: "",
    }
}

export const defaultStatusInfo: StatusInfo = {
    movingUp: {
        holding: {
            understanding: {
                suggest: false,
                process: false,
                thinking: false,
                connectWithDepartment: false,
            },
            commuStaff: {
                contacts: false,
                processStructure: false,
                policy: false,
                fund: false,
            },
            survey: {
                houseHoldSurvey: false,
                googleForm: false,
                googleMap: false,
                appLing: false,
                walkingMap: false,
            },
            savingGroups: {
                accountDoc: false,
                rules: false,
                goodProcessAccount: false,
            },
        },
        customize: {
            _2or3Months: {
                certOfSuffered: {
                    tableOfbeneficiary: false,
                    rulesOfCert: false,
                },
                summarizeOfSolutions: {
                    commuInfo: false
                },
                meetingCommu: {
                    meeting: false
                },
            },
            dependOnLand: {
                searchinLandForDevelop: {
                    mapOfLand: false
                },
                designCommuMap: {
                    landInfo: false,
                    titleDeed: false,
                    houseHoldInfo: false,
                    topography: false,
                },
                lendingOrSavingPlan: {
                    incomeAndExpenses: false,
                    housePlan: false,
                    housePrice: false,
                    savingPlan: false,
                },
            }
        },
        preparation: {
            coopHolding: {
                coopDocuments: false,
                coopWorkingProcess: false
            },
            buldingOrProcessingPlan: {
                commuMap: false,
                estimateTime: false
            },
            presentProject: {
                projectProposal: false,
                tableOfResponsiblePerson: false
            },
        },
    },
    moving: {
        following: {
            _1Month: {
                buyingIfGovLand: {
                    license: false
                },
            },
            _15daysTo1Month: {
                buildingAllowance: {
                    licenses: false
                }
            },
            orderingLang: {
                orderingBudget: {
                    orderForm: false,
                    housePlan: false,
                    summarizeMeeting: false,
                },
                findBuilder: {
                    findBuilder: false
                }
            },
            projectDurations: {
                building: {
                    building: false,
                },
                inspectSite: {
                    inspectForm: false,
                    buildingContract: false
                },
                approveProject: {
                    approvingForm: false
                },
                questionAndAnswer: {
                    questionForm: false,
                    questionProjectInfo: false
                }
            }
        },
        improvement: {
            durationsAndRules: {
                moreThanHouse: {
                    tutorialDocument: false,
                    basicInfo: false,
                }
            }
        }
    }
}

export type CommunityInformation = {
    community_info: CommuInfo;
    status_info: StatusInfo;
}