import React, { useEffect, useState } from 'react';
import statusKey from '../configs/statusKey';
import { StatusInfo } from '../models';
import useManagementStore from '../stores/communityInformation';
import { allValueOfKeyAreTrue } from '../utils';

const Customize: React.FC = () => {
    let statusInfo = useManagementStore((state) => state.statusInfo);
    let setStatusInfo = useManagementStore((state) => state.setStatusInfo);
    let isViewMode = useManagementStore((state) => state.isViewMode);

    const previousFormIsDone = (): boolean => {
        let temp = !JSON.stringify(statusInfo.movingUp.holding).includes('false')
        return temp
    }

    useEffect(() => {
        if (JSON.stringify(statusInfo.movingUp.holding).includes('false')) {
            statusInfo.movingUp.customize = JSON.parse(JSON.stringify(statusInfo.movingUp.customize).replaceAll('true', 'false'))
        }
    }, [statusInfo])

    const handle2To3Month = (parentKey: string, childrenKey: string) => {
        ((statusInfo.movingUp.customize._2or3Months as any)[parentKey] as any)[childrenKey] = !((statusInfo.movingUp.customize._2or3Months as any)[parentKey] as any)[childrenKey]
        if (parentKey === 'certOfSuffered' && childrenKey === 'tableOfbeneficiary' && !((statusInfo.movingUp.customize._2or3Months as any)[parentKey] as any)[childrenKey]) {
            let temp = JSON.stringify(statusInfo.movingUp.customize._2or3Months)
            temp = temp.replaceAll('true', 'false')
            statusInfo.movingUp.customize._2or3Months = JSON.parse(temp)
        }
        if (JSON.stringify(statusInfo.movingUp.customize._2or3Months.certOfSuffered).includes('false')) {
            statusInfo.movingUp.customize._2or3Months.summarizeOfSolutions = JSON.parse(JSON.stringify(statusInfo.movingUp.customize._2or3Months.summarizeOfSolutions).replaceAll('true', 'false'))
        }
        if (JSON.stringify(statusInfo.movingUp.customize._2or3Months.certOfSuffered).includes('false') || JSON.stringify(statusInfo.movingUp.customize._2or3Months.summarizeOfSolutions).includes('false')) {
            statusInfo.movingUp.customize._2or3Months.meetingCommu = JSON.parse(JSON.stringify(statusInfo.movingUp.customize._2or3Months.meetingCommu).replaceAll('true', 'false'))
        }
        let _2To3MonthNotDone = JSON.stringify(statusInfo.movingUp.customize._2or3Months).includes('false')
        if (_2To3MonthNotDone) {
            let temp = JSON.stringify(statusInfo.movingUp.customize.dependOnLand)
            temp = temp.replaceAll('true', 'false')
            statusInfo.movingUp.customize.dependOnLand = JSON.parse(temp)
        }
        setStatusInfo(statusInfo)
    }

    const handleDependOnLand = (parentKey: string, childrenKey: string) => {
        ((statusInfo.movingUp.customize.dependOnLand as any)[parentKey] as any)[childrenKey] = !((statusInfo.movingUp.customize.dependOnLand as any)[parentKey] as any)[childrenKey]
        let checkedValue = ((statusInfo.movingUp.customize.dependOnLand as any)[parentKey] as any)[childrenKey]

        if (!statusInfo.movingUp.customize.dependOnLand.searchinLandForDevelop.mapOfLand) {
            statusInfo.movingUp.customize.dependOnLand.designCommuMap = JSON.parse(JSON.stringify(statusInfo.movingUp.customize.dependOnLand.designCommuMap).replaceAll('true', 'false'))
        }

        if (parentKey === 'designCommuMap') {
            if (!statusInfo.movingUp.customize.dependOnLand.designCommuMap.landInfo) {
                statusInfo.movingUp.customize.dependOnLand.designCommuMap.titleDeed = JSON.parse(JSON.stringify(statusInfo.movingUp.customize.dependOnLand.designCommuMap.titleDeed).replaceAll('true', 'false'))
                statusInfo.movingUp.customize.dependOnLand.designCommuMap.houseHoldInfo = JSON.parse(JSON.stringify(statusInfo.movingUp.customize.dependOnLand.designCommuMap.houseHoldInfo).replaceAll('true', 'false'))
                statusInfo.movingUp.customize.dependOnLand.designCommuMap.topography = JSON.parse(JSON.stringify(statusInfo.movingUp.customize.dependOnLand.designCommuMap.topography).replaceAll('true', 'false'))
            }
            if (!statusInfo.movingUp.customize.dependOnLand.designCommuMap.titleDeed) {
                statusInfo.movingUp.customize.dependOnLand.designCommuMap.houseHoldInfo = JSON.parse(JSON.stringify(statusInfo.movingUp.customize.dependOnLand.designCommuMap.houseHoldInfo).replaceAll('true', 'false'))
                statusInfo.movingUp.customize.dependOnLand.designCommuMap.topography = JSON.parse(JSON.stringify(statusInfo.movingUp.customize.dependOnLand.designCommuMap.topography).replaceAll('true', 'false'))
            }
            if (!statusInfo.movingUp.customize.dependOnLand.designCommuMap.houseHoldInfo) {
                statusInfo.movingUp.customize.dependOnLand.designCommuMap.topography = JSON.parse(JSON.stringify(statusInfo.movingUp.customize.dependOnLand.designCommuMap.topography).replaceAll('true', 'false'))
            }
        }
        let designCommuMap = JSON.stringify(statusInfo.movingUp.customize.dependOnLand.designCommuMap)
        if (designCommuMap.includes('false')) {
            statusInfo.movingUp.customize.dependOnLand.lendingOrSavingPlan = JSON.parse(JSON.stringify(statusInfo.movingUp.customize.dependOnLand.lendingOrSavingPlan).replaceAll('true', 'false'))
        }

        if (parentKey === 'lendingOrSavingPlan') {
            if (!statusInfo.movingUp.customize.dependOnLand.lendingOrSavingPlan.incomeAndExpenses) {
                statusInfo.movingUp.customize.dependOnLand.lendingOrSavingPlan.housePlan = JSON.parse(JSON.stringify(statusInfo.movingUp.customize.dependOnLand.lendingOrSavingPlan.housePlan).replaceAll('true', 'false'))
                statusInfo.movingUp.customize.dependOnLand.lendingOrSavingPlan.housePrice = JSON.parse(JSON.stringify(statusInfo.movingUp.customize.dependOnLand.lendingOrSavingPlan.housePrice).replaceAll('true', 'false'))
                statusInfo.movingUp.customize.dependOnLand.lendingOrSavingPlan.savingPlan = JSON.parse(JSON.stringify(statusInfo.movingUp.customize.dependOnLand.lendingOrSavingPlan.savingPlan).replaceAll('true', 'false'))
            }
            if (!statusInfo.movingUp.customize.dependOnLand.lendingOrSavingPlan.housePlan) {
                statusInfo.movingUp.customize.dependOnLand.lendingOrSavingPlan.housePrice = JSON.parse(JSON.stringify(statusInfo.movingUp.customize.dependOnLand.lendingOrSavingPlan.housePrice).replaceAll('true', 'false'))
                statusInfo.movingUp.customize.dependOnLand.lendingOrSavingPlan.savingPlan = JSON.parse(JSON.stringify(statusInfo.movingUp.customize.dependOnLand.lendingOrSavingPlan.savingPlan).replaceAll('true', 'false'))
            }
            if (!statusInfo.movingUp.customize.dependOnLand.lendingOrSavingPlan.housePrice) {
                statusInfo.movingUp.customize.dependOnLand.lendingOrSavingPlan.savingPlan = JSON.parse(JSON.stringify(statusInfo.movingUp.customize.dependOnLand.lendingOrSavingPlan.savingPlan).replaceAll('true', 'false'))
            }
        }
        let customize = JSON.stringify(statusInfo.movingUp.customize)
        if (customize.includes('false')) {
            statusInfo.movingUp.preparation = JSON.parse(JSON.stringify(statusInfo.movingUp.preparation).replaceAll('true', 'false'))
        }
        setStatusInfo(statusInfo)
    }

    return (
        <div className='bg-grey-50 p-3'>
            <div>{`${statusKey.customize}`}</div>
            <div key="customize._2or3Months" className='container mx-auto p-3 rounded bg-slate-100 mt-1'>
                <div className='p-2'>{statusKey._2or3Months}</div>
                <div about='certOfSuffered' className='flex p-2 rounded bg-slate-200 grid grid-cols-3 mb-2'>
                    <div className='grow p-1 col-span-1 pl-2'>{statusKey.certOfSuffered}</div>
                    <div className='grow py-1 pr-2 col-span-2'>
                        <div className='flex grow justify-between pb-1 mb-1' key={statusKey.tableOfResponsiblePerson}>
                            <label>{statusKey.tableOfResponsiblePerson}</label>
                            <input
                                type="checkbox"
                                checked={statusInfo.movingUp.customize._2or3Months.certOfSuffered.tableOfbeneficiary}
                                onChange={() => handle2To3Month("certOfSuffered", "tableOfbeneficiary")}
                                className="form-checkbox ml-2 w-[3.618vw]"
                                disabled={!previousFormIsDone() || isViewMode}
                            />
                        </div>
                        <div className='flex grow justify-between pb-1 mb-1' key={statusKey.rulesOfCert}>
                            <label>{statusKey.rulesOfCert}</label>
                            <input
                                type="checkbox"
                                checked={statusInfo.movingUp.customize._2or3Months.certOfSuffered.rulesOfCert}
                                onChange={() => handle2To3Month("certOfSuffered", "rulesOfCert")}
                                className="form-checkbox ml-2 w-[3.618vw]"
                                disabled={!statusInfo.movingUp.customize._2or3Months.certOfSuffered.tableOfbeneficiary || !previousFormIsDone() || isViewMode}
                            />
                        </div>
                        <div className='flex grow bg-slate-300 py-1 justify-between rounded grid grid-cols-4'>
                            <div className='pl-1 col-span-2'>{statusKey.did}</div>
                            <div className='pl-1 col-span-1 flex justify-end'>=</div>
                            <div className='mr-1 col-span-1 flex justify-end'>
                                {allValueOfKeyAreTrue(statusInfo.movingUp.customize._2or3Months.certOfSuffered) ? 1 : 0}

                            </div>
                        </div>
                    </div>
                </div>

                <div about='summarizeOfSolutions' className='flex p-2 rounded bg-slate-200 grid grid-cols-3 mb-2'>
                    <div className='grow p-1 col-span-1 pl-2'>{statusKey.summarizeOfSolutions}</div>
                    <div className='grow py-1 pr-2 col-span-2'>
                        <div className='flex grow justify-between pb-1 mb-1' key={statusKey.commuInfo}>
                            <label>{statusKey.commuInfo}</label>
                            <input
                                type="checkbox"
                                checked={statusInfo.movingUp.customize._2or3Months.summarizeOfSolutions.commuInfo}
                                onChange={() => handle2To3Month("summarizeOfSolutions", "commuInfo")}
                                className="form-checkbox ml-2 w-[3.618vw]"
                                // disabled={!Object.keys(statusInfo.movingUp.customize._2or3Months.certOfSuffered).every((key) => ((statusInfo.movingUp.customize._2or3Months.certOfSuffered as any)[key] == true))}
                                disabled={!allValueOfKeyAreTrue(statusInfo.movingUp.customize._2or3Months.certOfSuffered) || !previousFormIsDone() || isViewMode}
                            />
                        </div>
                        <div className='flex grow bg-slate-300 py-1 justify-between rounded grid grid-cols-4'>
                            <div className='pl-1 col-span-2'>{statusKey.did}</div>
                            <div className='pl-1 col-span-1 flex justify-end'>=</div>
                            <div className='mr-1 col-span-1 flex justify-end'>
                                {allValueOfKeyAreTrue(statusInfo.movingUp.customize._2or3Months.summarizeOfSolutions) ? 1 : 0}

                            </div>
                        </div>
                    </div>
                </div>

                <div about='meetingCommu' className='flex p-2 rounded bg-slate-200 grid grid-cols-3 mb-2'>
                    <div className='grow p-1 col-span-1 pl-2'>{statusKey.meetingCommu}</div>
                    <div className='grow py-1 pr-2 col-span-2'>
                        <div className='flex grow justify-between pb-1 mb-1' key={statusKey.meetingCommu}>
                            <label>{statusKey.meetingCommu}</label>
                            <input
                                type="checkbox"
                                checked={statusInfo.movingUp.customize._2or3Months.meetingCommu.meeting}
                                onChange={() => handle2To3Month("meetingCommu", "meeting")}
                                className="form-checkbox ml-2 w-[3.618vw]"
                                // disabled={!Object.keys(statusInfo.movingUp.customize._2or3Months.summarizeOfSolutions).every((key) => ((statusInfo.movingUp.customize._2or3Months.summarizeOfSolutions as any)[key] == true))||!previousFormIsDone()}
                                disabled={!allValueOfKeyAreTrue(statusInfo.movingUp.customize._2or3Months.summarizeOfSolutions) || !previousFormIsDone() || isViewMode}
                            />
                        </div>
                        <div className='flex grow bg-slate-300 py-1 justify-between rounded grid grid-cols-4'>
                            <div className='pl-1 col-span-2'>{statusKey.did}</div>
                            <div className='pl-1 col-span-1 flex justify-end'>=</div>
                            <div className='mr-1 col-span-1 flex justify-end'>
                                {allValueOfKeyAreTrue(statusInfo.movingUp.customize._2or3Months.meetingCommu) ? 1 : 0}
                            </div>
                        </div>
                    </div>
                </div>

            </div>

            <div key="customize.dependOnLand" className='container mx-auto p-3 rounded bg-slate-100 mt-3'>
                <div className='p-2'>{statusKey.dependOnLand}</div>

                <div about='certOfSuffered' className='flex p-2 rounded bg-slate-200 grid grid-cols-3 mb-2'>
                    <div className='grow p-1 col-span-1 pl-2'>{statusKey.searchinLandForDevelop}</div>
                    <div className='grow py-1 pr-2 col-span-2'>
                        <div className='flex grow justify-between pb-1 mb-1' key={statusKey.mapOfLand}>
                            <label>{statusKey.mapOfLand}</label>
                            <input
                                type="checkbox"
                                checked={statusInfo.movingUp.customize.dependOnLand.searchinLandForDevelop.mapOfLand}
                                onChange={() => handleDependOnLand("searchinLandForDevelop", "mapOfLand")}
                                className="form-checkbox ml-2 w-[3.618vw]"
                                disabled={!allValueOfKeyAreTrue(statusInfo.movingUp.customize._2or3Months) || !previousFormIsDone() || isViewMode}
                            />
                        </div>
                        <div className='flex grow bg-slate-300 py-1 justify-between rounded grid grid-cols-4'>
                            <div className='pl-1 col-span-2'>{statusKey.did}</div>
                            <div className='pl-1 col-span-1 flex justify-end'>=</div>
                            <div className='mr-1 col-span-1 flex justify-end'>
                                {allValueOfKeyAreTrue(statusInfo.movingUp.customize.dependOnLand.searchinLandForDevelop) ? 1 : 0}
                            </div>
                        </div>
                    </div>
                </div>

                <div about='customize.designCommuMap' className='flex p-2 rounded bg-slate-200 grid grid-cols-3 mb-2'>
                    <div className='grow p-1 col-span-1 pl-2'>{statusKey.designCommuMap}</div>
                    <div className='grow py-1 pr-2 col-span-2'>
                        <div className='flex grow justify-between pb-1 mb-1' key={statusKey.designCommuMap}>
                            <label>{statusKey.landInfo}</label>
                            <input
                                type="checkbox"
                                checked={statusInfo.movingUp.customize.dependOnLand.designCommuMap.landInfo}
                                onChange={() => handleDependOnLand("designCommuMap", "landInfo")}
                                className="form-checkbox ml-2 w-[3.618vw]"
                                disabled={!allValueOfKeyAreTrue(statusInfo.movingUp.customize.dependOnLand.searchinLandForDevelop) || !previousFormIsDone() || isViewMode}
                            />
                        </div>
                        <div className='flex grow justify-between pb-1 mb-1' key={statusKey.titleDeed}>
                            <label>{statusKey.titleDeed}</label>
                            <input
                                type="checkbox"
                                checked={statusInfo.movingUp.customize.dependOnLand.designCommuMap.titleDeed}
                                onChange={() => handleDependOnLand("designCommuMap", "titleDeed")}
                                className="form-checkbox ml-2 w-[3.618vw]"
                                disabled={!statusInfo.movingUp.customize.dependOnLand.designCommuMap.landInfo || !previousFormIsDone() || isViewMode}
                            />
                        </div>
                        <div className='flex grow justify-between pb-1 mb-1' key={statusKey.houseHoldInfo}>
                            <label>{statusKey.houseHoldInfo}</label>
                            <input
                                type="checkbox"
                                checked={statusInfo.movingUp.customize.dependOnLand.designCommuMap.houseHoldInfo}
                                onChange={() => handleDependOnLand("designCommuMap", "houseHoldInfo")}
                                className="form-checkbox ml-2 w-[3.618vw]"
                                disabled={!statusInfo.movingUp.customize.dependOnLand.designCommuMap.titleDeed || !previousFormIsDone() || isViewMode}
                            />
                        </div>
                        <div className='flex grow justify-between pb-1 mb-1' key={statusKey.topography}>
                            <label>{statusKey.topography}</label>
                            <input
                                type="checkbox"
                                checked={statusInfo.movingUp.customize.dependOnLand.designCommuMap.topography}
                                onChange={() => handleDependOnLand("designCommuMap", "topography")}
                                className="form-checkbox ml-2 w-[3.618vw]"
                                disabled={!statusInfo.movingUp.customize.dependOnLand.designCommuMap.houseHoldInfo || !previousFormIsDone() || isViewMode}
                            />
                        </div>
                        <div className='flex grow bg-slate-300 py-1 justify-between rounded grid grid-cols-4'>
                            <div className='pl-1 col-span-2'>{statusKey.did}</div>
                            <div className='pl-1 col-span-1 flex justify-end'>=</div>
                            <div className='mr-1 col-span-1 flex justify-end'>
                                {allValueOfKeyAreTrue(statusInfo.movingUp.customize.dependOnLand.designCommuMap) ? 1 : 0}
                            </div>
                        </div>
                    </div>
                </div>

                <div about='customize.lendingOrSavingPlan' className='flex p-2 rounded bg-slate-200 grid grid-cols-3 mb-2'>
                    <div className='grow p-1 col-span-1 pl-2'>{statusKey.lendingOrSavingPlan}</div>
                    <div className='grow py-1 pr-2 col-span-2'>
                        <div className='flex grow justify-between pb-1 mb-1' key={statusKey.incomeAndExpenses}>
                            <label>{statusKey.incomeAndExpenses}</label>
                            <input
                                type="checkbox"
                                checked={statusInfo.movingUp.customize.dependOnLand.lendingOrSavingPlan.incomeAndExpenses}
                                onChange={() => handleDependOnLand("lendingOrSavingPlan", "incomeAndExpenses")}
                                className="form-checkbox ml-2 w-[3.618vw]"
                                disabled={!allValueOfKeyAreTrue(statusInfo.movingUp.customize.dependOnLand.designCommuMap) || !previousFormIsDone() || isViewMode}
                            />
                        </div>
                        <div className='flex grow justify-between pb-1 mb-1' key={statusKey.housePlan}>
                            <label>{statusKey.housePlan}</label>
                            <input
                                type="checkbox"
                                checked={statusInfo.movingUp.customize.dependOnLand.lendingOrSavingPlan.housePlan}
                                onChange={() => handleDependOnLand("lendingOrSavingPlan", "housePlan")}
                                className="form-checkbox ml-2 w-[3.618vw]"
                                disabled={!statusInfo.movingUp.customize.dependOnLand.lendingOrSavingPlan.incomeAndExpenses || !previousFormIsDone() || isViewMode}
                            />
                        </div>
                        <div className='flex grow justify-between pb-1 mb-1' key={statusKey.housePrice}>
                            <label>{statusKey.housePrice}</label>
                            <input
                                type="checkbox"
                                checked={statusInfo.movingUp.customize.dependOnLand.lendingOrSavingPlan.housePrice}
                                onChange={() => handleDependOnLand("lendingOrSavingPlan", "housePrice")}
                                className="form-checkbox ml-2 w-[3.618vw]"
                                disabled={!statusInfo.movingUp.customize.dependOnLand.lendingOrSavingPlan.housePlan || !previousFormIsDone() || isViewMode}
                            />
                        </div>
                        <div className='flex grow justify-between pb-1 mb-1' key={statusKey.savingPlan}>
                            <label>{statusKey.savingPlan}</label>
                            <input
                                type="checkbox"
                                checked={statusInfo.movingUp.customize.dependOnLand.lendingOrSavingPlan.savingPlan}
                                onChange={() => handleDependOnLand("lendingOrSavingPlan", "savingPlan")}
                                className="form-checkbox ml-2 w-[3.618vw]"
                                disabled={!statusInfo.movingUp.customize.dependOnLand.lendingOrSavingPlan.housePrice || !previousFormIsDone() || isViewMode}
                            />
                        </div>
                        <div className='flex grow bg-slate-300 py-1 justify-between rounded grid grid-cols-4'>
                            <div className='pl-1 col-span-2'>{statusKey.did}</div>
                            <div className='pl-1 col-span-1 flex justify-end'>=</div>
                            <div className='mr-1 col-span-1 flex justify-end'>
                                {allValueOfKeyAreTrue(statusInfo.movingUp.customize.dependOnLand.lendingOrSavingPlan) ? 1 : 0}
                            </div>
                        </div>
                    </div>
                </div>

            </div>



        </div>
    );
};

export default Customize;
