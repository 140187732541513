export default {
    did: "ทำครบ",
    movingUp: "ขาขึ้น",
    holding: "1. จัดตั้ง (4 คะแนน)",
    lessThan3Month: "ระยะเวลาและเกณฑ์ : ไม่เกิน 3 เดือน",
    understanding: "สร้างความเข้าใจ",
    suggest: "แนะนำพอช.",
    process: "กระบวนการบ้านมั่นคง",
    thinking: "หลักคิด/เกณฑ์",
    connectWithDepartment: "การเชื่อมโยงหน่วยงาน/ภาคี",
    commuStaff: "ทีมทำงานชุมชน",
    contacts: "รายชื่อ/เบอร์โทร ทีมทำงาน",
    processStructure: "โครงสร้างการทำงาน",
    policy: "นโยบาย/สังคม",
    fund: "กองทุน",
    survey: "สำรวจข้อมูล",
    houseHoldSurvey: "แบบสำรวจครัวเรือน",
    googleForm: "Google form แบบสำรวจครัวเรือน",
    googleMap: "Google Map (พิกัดบ้าน)",
    appLing: "App Ling (ขนาดบ้าน)",
    walkingMap: "ผังเดินดินชุมชน",
    savingGroups: "จัดตั้งกลุ่มออมทรัพย์",
    accountDoc: "เอกสารการทำบัญชี",
    rules: "กติกา/ข้อตกลง",
    goodProcessAccount: "ระบบการทำบัญชีที่ดี",
    customize: "2. แต่งตัว (6 คะแนน)",
    _2or3Months: "ระยะเวลาและเกณฑ์ : 2-3 เดือน (รวมประชุม,รับรองระดับชุมชน/เมือง)",
    certOfSuffered: "รับรองสิทธิ์ผู้เดือดร้อน***",
    tableOfbeneficiary: "ตารางผู้รับผลประโยชน์",
    rulesOfCert: "เกณฑ์การรับรองสิทธิ์",
    summarizeOfSolutions: "สรุปเพื่อหาแนวทางแก้ปัญหา",
    commuInfo: "ข้อมูลชุมชน",
    meetingCommu: "ประชุมรับรองระดับชุมชน/เมือง",
    meeting: "ประชุมรับรองระดับชุมชน/เมือง",
    dependOnLand: "ระยะเวลาและเกณฑ์ : ไม่กำหนด ขึ้นอยู่กับความ ชัดเจนที่ดิน",
    searchinLandForDevelop: "จัดหาพื้นที่ในการพัฒนา",
    mapOfLand: "แผนที่แสดงขอบเขตที่ดิน",
    designCommuMap: "ออกแบบวางผังชุมชนอย่างมีส่วนร่วม",
    landInfo: "ข้อมูลที่ดิน",
    titleDeed: "โฉนดที่ดิน",
    houseHoldInfo: "ข้้อมูลรายครัวเรือน",
    topography: "อัตลักษณ์พื้นที่",
    lendingOrSavingPlan: "อำนวยสินเชื่อ/วางแผนการออม",
    incomeAndExpenses: "รายได้-รายจ่าย รายครัวเรือน",
    housePlan: "แบบบ้าน",
    housePrice: "ราคาบ้าน/ที่ดิน",
    savingPlan: "แผนการออม",
    preparation: "3. เตรียมพร้อม (6 คะแนน)",
    _3Month: "ระยะเวลาและเกณฑ์ : ควบคู่กัน 3 เดือน (สหกรณ์) ขึ้นอยู่กับหน่วยงานในพื้นที่",
    coopHolding: "จัดตั้งสหกรณ์",
    coopDocuments: "เอกสารการบัญชีเกี่ยวกับสหกรณ์",
    coopWorkingProcess: "กลไกการร่วมมือกับกรมส่งเสริมสหกรณ์",
    buldingOrProcessingPlan: "จัดทำแผนก่อสร้าง/แผนดำเนินโครงการ",
    commuMap: "ผังชุมชน",
    estimateTime: "ประเมินระยะเวลาโครงการ/ข้อติดขัด",
    presentProject: "เสนอโครงการ",
    projectProposal: "แบบเสนอโครงการ",
    tableOfResponsiblePerson: "ตารางผู้รับผลประโยชน์",
    moving: "ขาเคลื่อน",
    following: "4. ติดตาม (6 คะแนน)",
    _1Month: "ระยะเวลาและเกณฑ์ : 30 วัน",
    buyingIfGovLand: "ซื้อ/ขอเช่าที่ดิน กรณีเป็นที่ดินรัฐ",
    license: "ใบอนุญาตเช่า / โฉนดที่ดิน",
    _15daysTo1Month: "ระยะเวลาและเกณฑ์ : 15-30 วัน",
    buildingAllowance: "ขออนุญาตก่อสร้าง",
    licenses: "ใบอนุุญาตก่อสร้าง",
    orderingLang: "ระยะเวลาและเกณฑ์ : เบิกซื้อที่ดิน\n(งบสินเชื่อ *สำหรับซื้อที่และสร้างบ้าน) ก่อนที่จะสามารถเบิกอุดหนุน->สาธา-บริหารจัดการ งวด 1 (งบอุดหนุน) ต้องเบิกหลังเสนอภายใน 6 เดือน",
    orderingBudget: "เบิกจ่ายงบประมาณ",
    orderForm: "แบบฟอร์มแผนการเบิก-จ่าย",
    summarizeMeeting: "มติที่ประชุม",
    findBuilder: "จัดหาผู้รับจ้าง",
    projectDurations: "ระยะเวลาและเกณฑ์ : 1-2 ปี แล้วแต่ระยะเวลาโครงการ",
    building: "ก่อสร้างบ้าน",
    inspectSite: "ตรวจงวดงาน",
    inspectForm: "แบบฟอร์มตรวจงวดงาน",
    buildingContract: "สัญญาผู้รับเหมา",
    approveProject: "ปิดโครงการ",
    approvingForm: "แบบฟอร์มปิดโครงการ",
    questionAndAnswer: "ติดตามสอบทาน",
    questionForm: "แบบฟอร์มสอบทาน",
    questionProjectInfo: "ข้อมูลการสอบทานโครงการ",
    improvement: "5. ต่อยอด (1 คะแนน)",
    durationsAndRules: "ระยะเวลาและเกณฑ์ : -",
    moreThanHouse: "บ้านที่มากกว่าบ้าน",
    tutorialDocument: "คู่มือ/กองทุน",
    basicInfo: "ข้อมูลพื้นฐาน (กลุ่มเปราะบาง)",
}