export default {
    URL: "https://mankhong.se7enetwork.net",
    // URL: "http://localhost:8381",


    AUTHEN_REFRESH: "/authen/refresh",
    AUTHEN_LOGIN: "/authen/login",

    COMMU: "/community",
    COMMU_LIST: "/community/list",
    COMMU_UPDATE: "/community/update",
    COMMU_CREATE: "/community/create",
}