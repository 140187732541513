import React from 'react';
import { useNavigate } from 'react-router-dom';
import useLogInStore from '../stores/login';

interface NavbarProps {
    children: React.ReactNode;
}

const Navbar: React.FC<NavbarProps> = ({ children }) => {
    const navigate = useNavigate();
    const logOut = useLogInStore((state) => state.logOut);
    const backToHomePage = () => {
        navigate('/')
        window.location.reload()
    };

    return (
        <div className="navbar top-0">
            <nav className="bg-white shadow fixed w-full z-40">
                <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                    <div className="flex items-center justify-between h-16">
                        <button className="flex items-center hover:bg-slate-200 hover:rounded hover:shadow p-3" onClick={backToHomePage}>
                            <span className="text-black text-xl font-bold">รายชื่อชุมชนทั้งหมด</span>
                        </button>
                        <div className="flex items-center">
                            <button className="bg-red-500 hover:bg-red-600 text-white font-semibold py-2 px-4 rounded" onClick={logOut}>
                                ออกจากระบบ
                            </button>
                        </div>
                    </div>
                </div>
            </nav>
            {children}
        </div>
    );
};

export default Navbar;
