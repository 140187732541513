import localStorageKey from "../../configs/localStorageKey";
import consts from "../../consts";
import { AUTHEN } from "../../types"
async function fetchRefresh(): Promise<AUTHEN | null> {
  if (localStorage.getItem(localStorageKey.accessToken)!) {
    const response = await fetch(`${consts.URL}${consts.AUTHEN_REFRESH}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "access-token": localStorage.getItem(localStorageKey.accessToken)!
      },
    });
    if (!response.ok) {
      const errorResponse = await response.json();
      return errorResponse
    }
    const data: AUTHEN = await response.json();
    return data;
  }
  return null
}


interface LoginForm {
  username: string;
  password: string;
}
async function postLogIn(inputData: LoginForm): Promise<AUTHEN> {
  const response = await fetch(`${consts.URL}${consts.AUTHEN_LOGIN}`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(inputData),
  })
  if (!response.ok) {
    const errorResponse = await response.json();
    return errorResponse
  }
  const data: AUTHEN = await response.json();
  return data
}



export {
  fetchRefresh,
  postLogIn
}



