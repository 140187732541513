import React, { useEffect, useState } from 'react';
import statusKey from '../configs/statusKey';
import { StatusInfo } from '../models';
import useManagementStore from '../stores/communityInformation';
import { allValueOfKeyAreTrue } from '../utils';

const Preparation: React.FC = () => {
    let statusInfo = useManagementStore((state) => state.statusInfo);
    let setStatusInfo = useManagementStore((state) => state.setStatusInfo);
    let isViewMode = useManagementStore((state) => state.isViewMode);

    const [keyOfThisPage, setKeyOfThisPage] = useState<string[]>(Object.keys(statusInfo.movingUp.preparation))
    const previousFormIsDone = (): boolean => {
        let temp = !JSON.stringify(statusInfo.movingUp.customize).includes('false')
        return temp
    }
    const handleWith3Month = (parentKey: string, childrenKey: string) => {
        ((statusInfo.movingUp.preparation as any)[parentKey] as any)[childrenKey] = !((statusInfo.movingUp.preparation as any)[parentKey] as any)[childrenKey]
        let preparation = JSON.stringify(statusInfo.movingUp.preparation).includes('false')
        if (preparation) {
            statusInfo.moving = JSON.parse(JSON.stringify(statusInfo.moving).replaceAll('true', 'false'))
        }
        setStatusInfo(statusInfo)
    }
    return (
        <div className='bg-grey-50 p-3'>
            <div>{`${statusKey.preparation}`}</div>
            <div className='container mx-auto p-3 rounded bg-slate-100 mt-1'>
                <div className='p-2'>{statusKey._3Month}</div>

                {keyOfThisPage.map((subTopic) => (
                    <div key={`statusInfo.movingUp.preparation.${subTopic}`} className='flex p-2 rounded bg-slate-200 grid grid-cols-3 mb-2'>
                        <div className='grow p-1 col-span-1 pl-2'>{(statusKey as any)[subTopic]}</div>
                        <div className='grow py-1 pr-2 col-span-2'>
                            {Object.keys((statusInfo.movingUp.preparation as any)[subTopic]).map((key) => (
                                <div className='flex grow justify-between pb-1 mb-1' key={key}>
                                    <label>{(statusKey as any)[key]}</label>
                                    <input
                                        type="checkbox"
                                        checked={((statusInfo.movingUp.preparation as any)[subTopic] as any)[key]}
                                        onChange={() => handleWith3Month(subTopic, key)}
                                        className="form-checkbox ml-2 w-[3.618vw]"
                                        disabled={!previousFormIsDone() || isViewMode}

                                    />
                                </div>
                            ))}
                            <div className='flex grow bg-slate-300 py-1 justify-between rounded grid grid-cols-4'>
                                <div className='pl-1 col-span-2'>{statusKey.did}</div>
                                <div className='pl-1 col-span-1 flex justify-end'>=</div>
                                <div className='mr-1 col-span-1 flex justify-end'>
                                    {allValueOfKeyAreTrue((statusInfo.movingUp.preparation as any)[subTopic]) ? 1 : 0}
                                </div>
                            </div>
                        </div>
                    </div>
                ))}

            </div>
        </div>
    );
};

export default Preparation;
