import * as authen from './authen'
import * as commuInfo from './commuInfo'

export const api = {
    users: {
        // fetch:x fetchUsers,
    },
    authen: {
        fetchRefresh: authen.fetchRefresh,
        postLogin: authen.postLogIn,
    },
    commuInfo: {
        fetchCommuList: commuInfo.fetchList,
        fetchCommuByID: commuInfo.fetchCommuByID,
        putCommuByID: commuInfo.putCommuByID,
        createCommu: commuInfo.createCommu,
    }
};
