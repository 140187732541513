import create from 'zustand';
import localStorageKey from '../configs/localStorageKey';
type LogInState = {
  isLogIn: boolean
  logIn: () => void;
  logOut: () => void;
};

const useLogInStore = create<LogInState>((set) => {
  const currentAccessToken = localStorage.getItem(localStorageKey.accessToken)
  const initIsLogInState = currentAccessToken !== null
  return {
    isLogIn: initIsLogInState,
    logIn: () => {
      set(() => ({ isLogIn: true }));
    },
    logOut: () => {
      set(() => ({ isLogIn: false }));
      localStorage.removeItem(localStorageKey.accessToken)
    },
  };
}

);

export default useLogInStore
