import create from 'zustand';
import localStorageKey from '../configs/localStorageKey';
import { CommuInfo, defaultCommuInfo, defaultStatusInfo, StatusInfo } from '../models';

type managementState = {
  commuInfo: CommuInfo,
  statusInfo: StatusInfo,
  province: string
  isViewMode: boolean,
  setCommuInfo: (newdata: CommuInfo) => void,
  setStatusInfo: (newdata: StatusInfo) => void,
  setIsView: (newMode: boolean) => void,
  setProvince: (newProvince: string) => void
};

const useManagementStore = create<managementState>((set) => {

  return {
    setIsView: (newMode: boolean) => {
      set(() => ({ isViewMode: newMode }))
    },
    setCommuInfo: (newdata: CommuInfo) => {
      set((state) => ({ commuInfo: { ...state.commuInfo, ...newdata } }));
    },
    setStatusInfo: (newdata: StatusInfo) => {
      set((state) => ({
        statusInfo: {
          ...state.statusInfo,
          ...newdata,
        },
      }));

    },
    setProvince: (newProvince: string) => {
      set((state) => ({
        province: newProvince
      }));
    },
    isViewMode: true,
    commuInfo: defaultCommuInfo,
    statusInfo: defaultStatusInfo,
    province: ""
  };
}

);

export default useManagementStore
