import React, { useEffect, useState } from 'react';
import { isError, QueryClient, useQuery, useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { api } from '../api';
import errorMsg from '../configs/error';
import localStorageKey from '../configs/localStorageKey';
import useLogInStore from '../stores/login';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons'

const LoginPage: React.FC = () => {
  const navigate = useNavigate();
  const logIn = useLogInStore((state) => state.logIn);
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  // const [errorMessage, setErrorMessage] = useState(error.usernameOrPasswordInvalid);
  const [errorMessage, setErrorMessage] = useState("");
  const { data: response, isLoading, isFetched, error, refetch, isRefetching, isFetching } = useQuery(
    ['authen', username, password],
    () => api.authen.postLogin({ username, password }),
    {
      enabled: false,
      retry: false,
      cacheTime: 0
    }
  )

  const [canSeePassword, setCanSeePassword] = useState<boolean>(false)

  const handleLogin = () => {
    if (username.length == 0 || password.length == 0) {
      setErrorMessage(errorMsg.emptyUsernameOrPassword)
    } else {
      refetch()
    }
  };
  useEffect(() => {
    if (response!) {
      if (response.status == "200") {
        setErrorMessage("")
        localStorage.setItem(localStorageKey.accessToken, response.data.token);
        logIn();
        navigate('/');
      } else {
        setErrorMessage(errorMsg.usernameOrPasswordInvalid)
      }
    }
    if (error!) {
      setErrorMessage(errorMsg.cantConnect)
    }
  }, [isFetched, error])


  return (
    <div className="flex items-center justify-center h-screen bg-white pb-4">
      <div className="bg-gray-100 shadow-lg rounded-lg p-8 mx-4 sm:mx-auto sm:max-w-md">
        <div className="text-center mb-6">
          <div className="text-3xl font-bold mb-4">เข้าสู่ระบบ</div>
          <div className="text-lg font-normal">เพื่ออัปเดตแก้ไขข้อมูลชุมชน</div>
        </div>
        <div className="space-y-4 px-12"
        // onSubmit={handleLogin}
        >
          <div>
            <label htmlFor="username" className="mb-1 font-medium">
              อีเมล/ชื่อผู้ใช้งาน
            </label>
            <input
              type="text"
              id="username"
              placeholder="ตย. example@email.com"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              className="px-4 py-2 border border-gray-300 rounded-md w-full"
            />
          </div>
          <div className='relative'>
            <label htmlFor="password" className="mb-1 font-medium">
              รหัสผ่าน
            </label>

            {/* <input
              type={canSeePassword ? 'input' : "password"}
              id="password"
              placeholder="รหัสผ่าน"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              className="px-4 py-2 border border-gray-300 rounded-md w-full"
            /> */}
            <input
              type={canSeePassword ? 'text' : 'password'} // Toggle between 'text' and 'password' type
              id="password"
              placeholder="รหัสผ่าน"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              className="px-4 py-2 border border-gray-300 rounded-md w-full pr-10" // Added 'pr-10' for extra padding on the right
            />
            {/* Eye icon to toggle password visibility */}
            <button
              type="button"
              className="absolute top-1/2 right-3 transform -translate-y-1/2 pb-1"
              onClick={() => setCanSeePassword(!canSeePassword)}
            >
              {canSeePassword ? <FontAwesomeIcon icon={faEye} /> : <FontAwesomeIcon icon={faEyeSlash} />}
            </button>



            <div
              className={`mt-1 h-6 overflow-hidden ${errorMessage ? 'visible' : 'invisible'
                }`}
            >
              <div className="text-red-500 text-sm mt-1">{errorMessage}</div>
            </div>

          </div>
          <button
            // type="submit"
            onClick={handleLogin}
            className="w-full px-4 py-3 bg-blue-500 hover:bg-blue-700 text-white rounded-md"
          >
            เข้าสู่ระบบ
          </button>
        </div>
      </div>
    </div >
  );
};

export default LoginPage;
