import { useEffect, useState } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import Management from './pages/Management';
import LoginPage from './pages/LogInPage';
import useLogInStore from './stores/login';
import Navbar from './components/Navbar';
import HomePage from './pages/HomePage';
import DetailsPage from './pages/DetailsPage';

import { api } from './api';
import { useQuery } from 'react-query';
import localStorageKey from './configs/localStorageKey';
const App: React.FC = () => {
  const isLogIn = useLogInStore((state) => state.isLogIn);
  const logOut = useLogInStore((state) => state.logOut);
  const { data: response, isLoading, isFetched, error, isError } = useQuery(
    'authen',
    api.authen.fetchRefresh,
    {
      enabled: true,
      retry: true,
      cacheTime: 0
    }
  )

  useEffect(() => {
    if (response!) {
      if (response.status != "200") {
        logOut()
      }
    }
  }, [response])

  // useEffect(() => { console.log('loaded this page') }, [])

  return (
    <Router>
      <Routes>
        <Route
          path="/"
          element={
            isLogIn ? (
              <div className="scrollable-page">
                <Navbar>
                  <HomePage />
                </Navbar>
              </div>
            ) : (
              <Navigate to="/_login" />
            )
          }
        />
        <Route
          path="/edit"
          element={
            isLogIn ? (
              <div className="scrollable-page">
                <Navbar>
                  <Management />
                </Navbar>
              </div>
            ) : (
              <Navigate to="/_login" />
            )
          }
        />
        <Route
          path="/create"
          element={
            isLogIn ? (
              <div className="scrollable-page">
                <Navbar>
                  <Management />
                </Navbar>
              </div>
            ) : (
              <Navigate to="/_login" />
            )
          }
        />
        <Route
          path="/_login"
          element={isLogIn ? <Navigate to="/" /> : <LoginPage />}
        />
      </Routes>
    </Router>
  );
};

export default App;