import React from 'react';

type DiscardChangesModalProps = {
  isOpen: boolean;
  onCancel: () => void;
  onDiscard: () => void;
};

const DiscardChangesModal: React.FC<DiscardChangesModalProps> = ({
  isOpen,
  onCancel,
  onDiscard,
}) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
      <div className="bg-white p-8 rounded-lg shadow-lg">
        <h2 className="text-xl font-semibold mb-4">
          การแก้ไขที่ยังไม่ได้บันทึก
        </h2>
        <p className="mb-4">คุณมีการแก้ไขที่ยังไม่ได้บันทึก ต้องการละทิ้งการแก้ไขเหล่านั้นหรือไม่ ?</p>
        <div className="flex justify-center">
          <button
            className="w-[30%] px-4 py-2 mr-2 text-gray-500 rounded border border-slate-500 hover:bg-slate-500 hover:text-white"
            onClick={onDiscard}
          >
            ละทิ้ง
          </button>
          <button
            onClick={onCancel}
            className="w-[30%] rounded px-4 py-2 bg-blue-500 text-white hover:bg-blue-500"
          >
            บันทึกการแก้ไข
          </button>
        </div>
      </div>
    </div>
  );
};

export default DiscardChangesModal;
