import React, { useEffect, useState } from 'react';
import statusKey from '../configs/statusKey';
import { StatusInfo } from '../models';
import Customize from './Customize';
import Following from './Following';
import Holding from './Holding';
import Improvement from './Improvement';
import Preparation from './Preparation';


const StatusCommu: React.FC = ({ }) => {
    useEffect(() => {
    }, [])

    return (
        <form className='pt-2 px-2'>
            <div>
                <div className='font-medium pl-2 text-xl'>{statusKey.movingUp}</div>
                <div className='pl-2'>
                    <Holding />
                    <Customize />
                    <Preparation />
                </div>
            </div>
            <div className='pt-4'>
                <div className='font-medium pl-2 pt-4 text-xl'>{statusKey.moving}</div>
                <div className='pl-2'>
                    <Following />
                    <Improvement />
                </div>
            </div>
        </form>
    );
};

export default StatusCommu;
