import React from 'react';
import ModalImage from '../image/Modal.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faXmark } from '@fortawesome/free-solid-svg-icons'
type StatusDetailModalProps = {
  isOpen: boolean;
  onClose: () => void;
};

const StatusDetailModal: React.FC<StatusDetailModalProps> = ({
  isOpen,
  onClose,
}) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50 p-10">
      <div className="bg-white p-4 rounded-lg shadow-lg">
        <div className='flex flex-grow justify-between items-center mb-4'>
          <h2 className="text-xl font-semibold">
            คำอธิบายสถานะชุมชน
          </h2>
          <button
            onClick={onClose}
          >
            <FontAwesomeIcon icon={faXmark} fontSize={30} />
          </button>
        </div>

        <img src={ModalImage} className="p-2 w-full h-auto max-w-screen"/>
      </div>
    </div>
  );
};

export default StatusDetailModal;
