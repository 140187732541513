import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import ViewCommuInfo from '../components/ViewCommuInfo';
import Navbar from '../components/Navbar';
import useLogInStore from '../stores/login';
import EditCommuInfo from '../components/EditCommuInfo';
import { CommuInfo, defaultCommuInfo, defaultStatusInfo, StatusInfo } from '../models';
import StatusCommu from '../components/StatusCommu';
import useManagementStore from '../stores/communityInformation';
import DiscardChangesModal from '../components/DiscardChangesModal';
import { useQuery } from 'react-query';
import { api } from '../api';
import errorMsg from '../configs/error';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleLeft, faCircleExclamation } from '@fortawesome/free-solid-svg-icons'
import StatusDetailModal from '../components/StatusDetailModal';

enum Tab {
  CONTENT1 = 'ข้อมูลชุมชน',
  CONTENT2 = 'สถานะชุมชน',
}

type ManagementProps = {
  commuInfo?: CommuInfo;
};

const Management: React.FC<ManagementProps> = ({ }) => {
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = React.useState(Tab.CONTENT1);

  const { commuInfo, isViewMode, setCommuInfo, setIsView, setStatusInfo, statusInfo, province, setProvince } = useManagementStore((state) => state);

  const [tempCommuInfo, setTempCommuInfo] = useState<CommuInfo>();
  const [tempStatusInfo, setTempStatusInfo] = useState<StatusInfo>();
  const [tempProvince, setTempProvince] = useState<string>();


  const location = useLocation();
  const isIdExists = location.search.includes('id');
  const isCreate = window.location.pathname.includes('create')
  const urlParams = new URLSearchParams(window.location.search);
  const id = Number(urlParams.get('id')) ?? 0;

  const { data: response, isLoading, isFetched, error, isSuccess, refetch } = useQuery(['commuInfo', id], () => api.commuInfo.fetchCommuByID(id), {
    enabled: false,
  })

  const [isItBack, setIsItBack] = useState<boolean>(false)

  const putCommuByID = useQuery(['commuInfo', {
    id: id,
    commu_name: commuInfo.commuName,
    community_information: {
      community_info: commuInfo,
      status_info: statusInfo,
    },
    province: province
  }], () => api.commuInfo.putCommuByID({
    id: id,
    commu_name: commuInfo.commuName,
    community_information: {
      community_info: commuInfo,
      status_info: statusInfo,
    },
    province: province
  }), {
    enabled: false,
    retry: false
  })

  const createCommu = useQuery(['commuInfo', {
    commu_name: commuInfo.commuName,
    community_information: {
      community_info: commuInfo,
      status_info: statusInfo,
    }
  }], () => api.commuInfo.createCommu({
    commu_name: commuInfo.commuName,
    community_information: {
      community_info: commuInfo,
      status_info: statusInfo,
    },
    province: province
  }), {
    enabled: false,
    retry: false
  })

  const [isOpen, setIsOpen] = React.useState<boolean>(false)
  const [isDetailOpen, setIsDetailOpen] = React.useState<boolean>(false)

  useEffect(() => {
  }, [])

  useEffect(() => {

    if (isCreate) {
      setCommuInfo(defaultCommuInfo)
      setTempCommuInfo(JSON.parse(JSON.stringify(defaultCommuInfo)) as CommuInfo)
      setStatusInfo(defaultStatusInfo)
      setTempStatusInfo(JSON.parse(JSON.stringify(defaultStatusInfo)) as StatusInfo)
      setProvince("")
      setTempProvince("")
      setIsView(false)
    } else {
      if (!isIdExists) {
        navigate('/')
      } else {
        refetch()
      }
    }
  }, [isIdExists, isCreate])



  useEffect(() => {
    if (response! && isSuccess && isFetched && !isCreate) {
      setCommuInfo(response.data.community_information.community_info)
      setStatusInfo(response.data.community_information.status_info)
      setProvince(response.data.province)
      setTempProvince(JSON.parse(JSON.stringify(response.data.province)))
      // if (tempCommuInfo == undefined)
      { setTempCommuInfo(JSON.parse(JSON.stringify(response.data.community_information.community_info)) as CommuInfo) }
      // if (tempStatusInfo == undefined)
      { setTempStatusInfo(JSON.parse(JSON.stringify(response.data.community_information.status_info)) as StatusInfo) }
    }
    if (error!) {
      window.alert(errorMsg.cantConnect)
      navigate('/')
    }

  }, [response, isFetched, isSuccess, error])

  const handleTabClick = (tab: Tab) => {
    setActiveTab(tab);
  };

  const handleSaveInfo = () => {
    // TODO : update data to backend and fetch again
    if (commuInfo.commuName.length < 1 || province.length < 1) {
      return
    }
    if (isCreate) {

      createCommu.refetch().then((createdResponse) => {
        if (createdResponse.data?.status == "500") {
          window.alert(createdResponse.data?.msg)
          setIsItBack(false)
          setIsView(false)
        } else {
          { setTempCommuInfo(JSON.parse(JSON.stringify(createdResponse.data?.data.community_information.community_info)) as CommuInfo) }
          { setTempStatusInfo(JSON.parse(JSON.stringify(createdResponse.data?.data.community_information.status_info)) as StatusInfo) }
          setTempProvince(JSON.parse(JSON.stringify(createdResponse.data?.data.province)))
          setIsView(true)
          setIsItBack(false)
          navigate('/')
        }
      })

    } else {
      setIsView(true)
      putCommuByID.refetch().then(() => {
        if (isItBack) {
          navigate('/')
          setIsItBack(false)
        }
        refetch()
        // refetch()
        //   .then(() => {
        //     let { data: newResponse } = putCommuByID
        //     let newData = newResponse?.data
        //     console.log(newData)
        //     // setTempCommuInfo(JSON.parse(JSON.stringify(newData!.community_information.community_info)) as CommuInfo)
        //     // setTempStatusInfo(JSON.parse(JSON.stringify(newData!.community_information.status_info)) as StatusInfo)
        //   })
        // if (isItBack) {
        //   navigate('/')
        //   setIsItBack(false)
        // }
      })
    }
  }

  const handleModalCancel = () => {
    setIsOpen(false)
    handleSaveInfo()
  }
  const handleModalDiscard = () => {
    setIsOpen(false)
    setCommuInfo(tempCommuInfo!)
    setStatusInfo(tempStatusInfo!)
    setProvince(tempProvince!)
    setIsView(true)
    refetch()
    if (isItBack) {
      setIsItBack(false)
      navigate('/')
    }
  }


  const isItChange = () => {
    let a = JSON.stringify(commuInfo)
    let b = JSON.stringify(tempCommuInfo!)
    let c = JSON.stringify(statusInfo)
    let d = JSON.stringify(tempStatusInfo!)
    let e = JSON.stringify(province)
    let f = JSON.stringify(tempProvince)
    return (a !== b || c !== d || e !== f)
  };

  // useEffect(() => {
  //   console.log(isItBack)
  // }, [isItBack])

  const backToHomePage = () => {
    setIsItBack(true)
    if (isItChange()) {
      setIsOpen(true)
    } else {
      setIsItBack(false)
      setIsView(true)
      refetch()
        .then(() => {
          setCommuInfo(tempCommuInfo!)
          setStatusInfo(tempStatusInfo!)
          setProvince(tempProvince!)

        })
      navigate('/')
    }
  };

  return (
    <div className="pt-4 flex flex-col min-h-screen">
      <DiscardChangesModal
        isOpen={isOpen} onCancel={handleModalCancel} onDiscard={handleModalDiscard}
      />
      <StatusDetailModal
        isOpen={isDetailOpen} onClose={() => { setIsDetailOpen(false) }}
      />
      <main className="flex-grow">
        <div className="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8 mt-14">
          <div className='flex justify-between items-center px-2 py-2'>
            <div className='font-bold text-2xl flex flex-grow items-center'>
              <button
                onClick={backToHomePage}
                className='text-4xl'>
                <FontAwesomeIcon icon={faAngleLeft} />
              </button>
              <div className='pl-5 pb-1'>ชุมชน {commuInfo.commuName || '-'}</div>
            </div>
            {isViewMode && <button
              className="bg-blue-500 hover:bg-blue-700 text-white py-3 rounded-md ml-2 w-auto px-[3vw]"
              onClick={() => setIsView(false)}
            >
              แก้ไข
            </button>}
          </div>
          <div className="shadow-xl sm:rounded-lg pt-2 pb-6 px-1 mt-6 ">

            <div className="mb-6 p-4">
              <ul className="flex border-b">
                <li
                  className={`-mb-px mr-1 ${activeTab === Tab.CONTENT1 ? 'border-b-2 border-blue-900' : ''}`}
                >
                  <button
                    className={`bg-white inline-block py-2 px-4 font-semibold` + (activeTab === Tab.CONTENT1 ? ` text-blue-900` : ``)}
                    onClick={() => handleTabClick(Tab.CONTENT1)}
                  >
                    {Tab.CONTENT1}
                  </button>
                </li>
                <li
                  className={`-mb-px mr-1 ${activeTab === Tab.CONTENT2 ? 'border-b-2 border-blue-900' : ''}`}
                >
                  <button
                    className={`bg-white inline-block py-2 px-4 font-semibold flex flew-grow items-center` + (activeTab === Tab.CONTENT2 ? ` text-blue-900` : ``)}
                    onClick={() => handleTabClick(Tab.CONTENT2)}
                  >
                    {Tab.CONTENT2}
                    <button>
                      <FontAwesomeIcon icon={faCircleExclamation} fontSize={18}
                        className="pl-2"
                        onClick={() => setIsDetailOpen(true)}
                      />
                    </button>
                  </button>
                </li>
              </ul>
            </div>

            {activeTab === Tab.CONTENT1 && (
              isViewMode ?
                <ViewCommuInfo /> :
                <EditCommuInfo />
            )}
            {activeTab === Tab.CONTENT2 && (
              <div>
                <StatusCommu />
              </div>
            )}
            {isViewMode == false && <div className='flex justify-center my-3 py-2'>
              <button
                className="border border-blue-500 text-blue-500 hover:bg-blue-300 hover:text-white py-3 rounded-md ml-2 w-auto px-[2vw]"
                onClick={() => { setIsOpen(true) }}
              >
                ยกเลิก
              </button>
              <button
                className="bg-blue-500 hover:bg-blue-700 text-white py-3 rounded-md ml-2 w-auto px-[2vw]"
                onClick={handleSaveInfo}
              >
                บันทึก
              </button>
            </div>}
          </div>
        </div>
      </main>
    </div>
  );
};

export default Management;
