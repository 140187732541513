import React, { useState } from "react";
import useManagementStore from "../stores/communityInformation";
import { allValueOfKeyAreTrue } from "../utils";
import { formatPhoneNumber } from "../utils/phoneFormat";
const ViewCommuInfo: React.FC = () => {
    let commuInfo = useManagementStore((state) => state.commuInfo);
    let statusInfo = useManagementStore((state) => state.statusInfo);
    let province = useManagementStore((state) => state.province);

    const statusOfThisCommunity = () => {
        let status = "จัดตั้ง (1/4)"
        let holding = allValueOfKeyAreTrue(statusInfo.movingUp.holding) ? 1 : 0
        let customize = allValueOfKeyAreTrue(statusInfo.movingUp.customize) ? 1 : 0
        let preparation = allValueOfKeyAreTrue(statusInfo.movingUp.preparation) ? 1 : 0
        let following = allValueOfKeyAreTrue(statusInfo.moving.following) ? 1 : 0
        if (holding) {
            status = `จัดตั้ง (1/4)`
        }
        if (customize) {
            status = `แต่งตัว (2/4)`
        }
        if (preparation) {
            status = `เตรียมพร้อม (3/4)`
        }
        if (following) {
            status = `ติดตาม (2/4)`
        }
        return status
    }

    return (
        <div className="flex flex-wrap px-2">
            <div className="w-full sm:w-1/2 px-2">
                <div className="font-semibold text-lg">ทั่วไป</div>
                <div className="pt-6">
                    <div className="font-semibold">ชื่อชุมชน</div>
                    <div className="pt-1">{commuInfo.commuName || '-'}</div>
                </div>
                <div className="pt-6">
                    <div className="font-semibold">จังหวัด</div>
                    <div className="pt-1">{province || '-'}</div>
                </div>
                <div className="pt-6">
                    <div className="font-semibold">ประธานชุมชน</div>
                    <div className="pt-1">{commuInfo.chiefName || '-'}</div>
                </div>
                <div className="pt-6">
                    <div className="font-semibold">เบอร์ติดต่อประธานชุมชน</div>
                    <div className="pt-1">{formatPhoneNumber(commuInfo.chiefPhoneNumber) || '-'}</div>
                </div>

                <div className="pt-6">
                    <div className="font-semibold">สถานะชุมชน</div>
                    <div className="pt-1">{statusOfThisCommunity()}</div>
                </div>

                <div className="pt-6">
                    <div className="font-semibold">พิกัด WKT Point</div>
                    <div className="pt-1">{`(${commuInfo.wkt1}, ${commuInfo.wkt2})`}</div>

                </div>
                <div className="pt-6">
                    <div className="font-semibold">Google Map</div>
                    <div className="pt-1">{commuInfo.googleMapURL || '-'}</div>
                </div>
            </div>
            <div className="w-full sm:w-1/2 px-2">
                <div className="font-semibold text-lg">สถานะปัจจุบันและสภาพที่อยู่อาศัย</div>
                <div className="pt-6">
                    <div className="font-semibold">สามารถออมได้</div>
                    <div>{Number(commuInfo.savingPerMonth || '0').toLocaleString('en-US')} บาท/เดือน</div>
                </div>

                <div className="pt-6">
                    <div className="font-semibold">ประเภทที่ดิน (4 อันดับแรก เรียงจากมากไปน้อย)</div>
                    <div className="pt-1">อันดับที่ 1 {commuInfo.fieldType.no1 || '-'}</div>
                    <div className="pt-1">อันดับที่ 2 {commuInfo.fieldType.no2 || '-'}</div>
                    <div className="pt-1">อันดับที่ 3 {commuInfo.fieldType.no3 || '-'}</div>
                    <div className="pt-1">อันดับที่ 4 {commuInfo.fieldType.no4 || '-'}</div>
                </div>

                <div className="pt-6">
                    <div className="font-semibold">ประเภทกรรมสิทธิ์ (4 อันดับแรก เรียงจากมากไปน้อย)</div>
                    <div className="pt-1">อันดับที่ 1 {commuInfo.ownerType.no1 || '-'}</div>
                    <div className="pt-1">อันดับที่ 2 {commuInfo.ownerType.no2 || '-'}</div>
                    <div className="pt-1">อันดับที่ 3 {commuInfo.ownerType.no3 || '-'}</div>
                    <div className="pt-1">อันดับที่ 4 {commuInfo.ownerType.no4 || '-'}</div>
                </div>
            </div>
        </div>
    )
};

export default ViewCommuInfo;