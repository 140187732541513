import localStorageKey from "../../configs/localStorageKey";
import consts from "../../consts";
import { COMMU, COMMU_LIST } from "../../types"

import { CommunityCreating, CommunityInformationRawData } from "../../types/apiCommuInfo";

async function fetchList(): Promise<COMMU_LIST | null> {
  if (localStorage.getItem(localStorageKey.accessToken)!) {
    const response = await fetch(`${consts.URL}${consts.COMMU_LIST}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "access-token": localStorage.getItem(localStorageKey.accessToken)!
      },
    });
    if (!response.ok) {
      const errorResponse = await response.json();
      return errorResponse
    }
    const data: COMMU_LIST = await response.json();
    return data;
  }
  return null
}

async function fetchCommuByID(id: number): Promise<COMMU | null> {
  if (localStorage.getItem(localStorageKey.accessToken)!) {
    const response = await fetch(`${consts.URL}${consts.COMMU}/${id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "access-token": localStorage.getItem(localStorageKey.accessToken)!
      },
    });
    if (!response.ok) {
      const errorResponse = await response.json();
      return errorResponse
    }
    const data: COMMU = await response.json();
    return data;
  }
  return null
}

async function putCommuByID(newData: CommunityInformationRawData): Promise<COMMU | null> {
  const id = newData.id
  if (localStorage.getItem(localStorageKey.accessToken)!) {
    const response = await fetch(`${consts.URL}${consts.COMMU_UPDATE}/${id}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        "access-token": localStorage.getItem(localStorageKey.accessToken)!
      },
      body: JSON.stringify(newData as any),
    });
    if (!response.ok) {
      const errorResponse = await response.json();
      return errorResponse
    }
    const data: COMMU = await response.json();
    return data;
  }
  return null
}

async function createCommu(newData: CommunityCreating): Promise<COMMU | null> {
  if (localStorage.getItem(localStorageKey.accessToken)!) {
    const response = await fetch(`${consts.URL}${consts.COMMU_CREATE}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "access-token": localStorage.getItem(localStorageKey.accessToken)!
      },
      body: JSON.stringify(newData as any),
    });
    if (!response.ok) {
      const errorResponse = await response.json();
      return errorResponse
    }
    const data: COMMU = await response.json();
    return data;
  }
  return null
}


export {
  fetchList,
  fetchCommuByID,
  putCommuByID,
  createCommu
}



