import React, { useEffect, useState } from 'react';
import statusKey from '../configs/statusKey';
import { StatusInfo } from '../models';
import useManagementStore from '../stores/communityInformation';
import { allValueOfKeyAreTrue } from '../utils';

const Holding: React.FC = () => {
    let statusInfo = useManagementStore((state) => state.statusInfo);
    let isViewMode = useManagementStore((state) => state.isViewMode);
    let setStatusInfo = useManagementStore((state) => state.setStatusInfo);
    const [keyOfThisPage, setKeyOfThisPage] = useState<string[]>(Object.keys(statusInfo.movingUp.holding))

    useEffect(() => {
    }, [statusInfo])

    const handleLessThan3Month = (parentKey: string, childrenKey: string) => {
        ((statusInfo.movingUp.holding as any)[parentKey] as any)[childrenKey] = !((statusInfo.movingUp.holding as any)[parentKey] as any)[childrenKey]
        let holdingNotDone = JSON.stringify(statusInfo.movingUp.holding).includes('false')
        if (holdingNotDone) {
            statusInfo.movingUp.customize = JSON.parse(JSON.stringify(statusInfo.movingUp.customize).replaceAll('true', 'false'))
            statusInfo.movingUp.preparation = JSON.parse(JSON.stringify(statusInfo.movingUp.preparation).replaceAll('true', 'false'))
            statusInfo.moving = JSON.parse(JSON.stringify(statusInfo.moving).replaceAll('true', 'false'))
        }
        setStatusInfo(statusInfo)
    }

    return (
        <div className='bg-grey-50 p-3'>
            <div>{`${statusKey.holding}`}</div>
            <div className='container mx-auto p-3 rounded bg-slate-100 mt-1'>
                <div className='p-2'>{statusKey.lessThan3Month}</div>

                {keyOfThisPage.map((subTopic) => (
                    <div key={`statusInfo.movingUp.holding.lessThan3Month.${subTopic}`} about={`statusInfo.movingUp.holding.lessThan3Month.${subTopic}`} className='flex p-2 rounded bg-slate-200 grid grid-cols-3 mb-2'>
                        <div className='grow p-1 col-span-1 pl-2'>{(statusKey as any)[subTopic]}</div>
                        <div className='grow py-1 pr-2 col-span-2'>
                            {Object.keys((statusInfo.movingUp.holding as any)[subTopic]).map((key) => (
                                <div className='flex grow justify-between pb-1 mb-1' key={key}>
                                    <label>{(statusKey as any)[key]}</label>
                                    <input
                                        type="checkbox"
                                        checked={((statusInfo.movingUp.holding as any)[subTopic] as any)[key]}
                                        onChange={() => handleLessThan3Month(subTopic, key)}
                                        className="form-checkbox ml-2 w-[3.618vw]"
                                        disabled={isViewMode}
                                    />
                                </div>
                            ))}
                            <div className='flex grow bg-slate-300 py-1 justify-between rounded grid grid-cols-4'>
                                <div className='pl-1 col-span-2'>{statusKey.did}</div>
                                <div className='pl-1 col-span-1 flex justify-end'>=</div>
                                <div className='mr-1 col-span-1 flex justify-end'>
                                    {allValueOfKeyAreTrue((statusInfo.movingUp.holding as any)[subTopic]) ? 1 : 0}
                                </div>
                            </div>
                        </div>
                    </div>
                ))}

            </div>
        </div>
    );
};

export default Holding;
