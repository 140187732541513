import React, { useEffect, useState } from "react";
import { CommuInfo } from "../models";
import useManagementStore from "../stores/communityInformation";
import { formatPhoneNumber } from "../utils/phoneFormat";


const EditCommuInfo: React.FC = ({ }) => {
    let commuInfo = useManagementStore((state) => state.commuInfo);
    let setCommuInfo = useManagementStore((state) => state.setCommuInfo);
    let isViewMode = useManagementStore((state) => state.isViewMode);
    let province = useManagementStore((state) => state.province);
    let setProvince = useManagementStore((state) => state.setProvince);


    const [selectedOptions, setSelectedOptions] = useState(["", "", "", ""]);
    const fieldTypeOptions = [
        "เอกชน",
        "เจ้าท่า",
        "สปก.",
        "ทช.",
        "ศาสนสถาน",
        "อุทยาน",
        "กรมทรัพย์สินส่วนพระมหากษัตริย์",
        "กรมธนารักษ์",
        "การรถไฟแห่งประเทศไทย",
    ];
    const ownerTypeOptions = [
        "บุกรุก",
        "เช่าอยู่แบบมีสัญญาเช่า",
        "เช่าอยู่แบบไม่มีสัญญาเช่า",
    ];

    const handleUpdateFormNoNumber = (key: string, newValue: string) => {
        const sanitizedValue = newValue.replace(/[0-9!@#$%^&*/\\]/g, '');
        (commuInfo as any)[key] = sanitizedValue
        setCommuInfo(commuInfo)
    }
    const handleUpdateProvice = (newValue: string) => {
        const sanitizedValue = newValue.replace(/[0-9!@#$%^&*/\\]/g, '');
        setProvince(sanitizedValue)
    }
    const handleUpdateFormNumber = (key: string, newValue: string) => {
        const sanitizedValue = newValue.replace(/\D/g, '');
        (commuInfo as any)[key] = sanitizedValue
        setCommuInfo(commuInfo)
    }
    const handleUpdateJustText = (key: string, newValue: string) => {
        (commuInfo as any)[key] = newValue
        setCommuInfo(commuInfo)
    }
    const handleSelect = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const { name, value } = event.target;
    };

    const handleFieldType = (event: React.ChangeEvent<HTMLSelectElement>, order: string) => {
        (commuInfo.fieldType as any)[order] = event.target.value
        setCommuInfo(commuInfo)

    }
    const handleOwnerType = (event: React.ChangeEvent<HTMLSelectElement>, order: string) => {
        (commuInfo.ownerType as any)[order] = event.target.value
        setCommuInfo(commuInfo)

    }
    useEffect(() => {
    }, [commuInfo])

    return (
        <div className="flex flex-wrap px-2">
            <div className="w-full sm:w-1/2 px-2">
                <div className="font-semibold text-lg">ทั่วไป</div>
                <div className="pt-6">
                    <div className="font-semibold">ชื่อชุมชน</div>
                    <input
                        type="text"
                        id="commuName"
                        placeholder="ชื่อชุมชน"
                        value={commuInfo.commuName}
                        onChange={(e) => handleUpdateFormNoNumber("commuName", e.target.value)}
                        className={
                            `px-4 py-2 border border-gray-300 rounded-md w-full md:w-4/5
                            ${commuInfo.commuName.length >= 1
                                ? ""
                                : "border-red-500 rounded-md border"
                            }`
                        }
                    />
                    <div className="h-1 mt-2">
                        {
                            commuInfo.commuName.length >= 1
                                ? <p className="text-red-500 text-xs hidden">.</p>
                                : <p className="text-red-500 text-xs">กรุณากรอกชื่อชุมชน</p>
                        }
                    </div>
                </div>
                <div className="pt-6">
                    <div className="font-semibold">จังหวัด</div>
                    <input
                        type="text"
                        id="province"
                        placeholder="จังหวัด"
                        value={province}
                        onChange={(e) => handleUpdateProvice(e.target.value)}
                        className={
                            `px-4 py-2 border border-gray-300 rounded-md w-full md:w-4/5
                            ${province.length >= 1
                                ? ""
                                : "border-red-500 rounded-md border"
                            }`
                        }
                    />
                    <div className="h-1 mt-2">
                        {
                            province.length >= 1
                                ? <p className="text-red-500 text-xs hidden">.</p>
                                : <p className="text-red-500 text-xs">กรุณากรอกชื่อจังหวัด</p>
                        }
                    </div>
                </div>
                <div className="pt-6">
                    <div className="font-semibold">ประธานชุมชน</div>
                    <input
                        type="text"
                        id="chiefName"
                        placeholder="ประธานชุมชน"
                        value={commuInfo.chiefName}
                        onChange={(e) => handleUpdateFormNoNumber("chiefName", e.target.value)}
                        className="px-4 py-2 border border-gray-300 rounded-md w-full md:w-4/5"
                    />
                </div>
                <div className="pt-6">
                    <div className="font-semibold">เบอร์ติดต่อประธานชุมชน</div>
                    <input
                        type="tel"
                        id="chiefPhoneNumber"
                        placeholder="000-000-0000"
                        maxLength={10}
                        value={formatPhoneNumber(commuInfo.chiefPhoneNumber)}
                        onChange={(e) => handleUpdateFormNumber("chiefPhoneNumber", e.target.value)}
                        className="px-4 py-2 border border-gray-300 rounded-md w-full md:w-4/5"
                    />
                </div>
                <div className="pt-6">
                    <div className="font-semibold">พิกัด WKT Point</div>
                    <div className="flex w-full md:w-4/5 space-x-6 grid grid-cols-2">
                        <input
                            type="text"
                            id="wkt1"
                            placeholder="00.0000"
                            value={commuInfo.wkt1}
                            onChange={(e) => handleUpdateJustText("wkt1", e.target.value)}
                            className="col-span-1 px-4 py-2 border border-gray-300 rounded-md"
                        />
                        <input
                            type="text"
                            id="wkt1"
                            placeholder="00.0000"
                            value={commuInfo.wkt2}
                            onChange={(e) => handleUpdateJustText("wkt2", e.target.value)}
                            className="col-span-1 px-4 py-2 border border-gray-300 rounded-md"
                        />
                    </div>
                    <div className="pt-1">ตัวอย่าง (11.673695, 102.543243)</div>

                </div>
                <div className="pt-6">
                    <div className="font-semibold">Google Map</div>
                    <input
                        type="text"
                        id="googleMapURL"
                        placeholder="ตย. https://www.google.com/maps/place/example"
                        value={commuInfo.googleMapURL}
                        onChange={(e) => handleUpdateJustText("googleMapURL", e.target.value)}
                        className="px-4 py-2 border border-gray-300 rounded-md w-full md:w-4/5"
                    />
                </div>
            </div>
            <div className="w-full sm:w-1/2 px-2">
                <div className="font-semibold text-lg">สถานะปัจจุบันและสภาพที่อยู่อาศัย</div>
                <div className="pt-6">
                    <div className="font-semibold">สามารถออมได้</div>
                    <div className="flex items-center space-x-2">
                        <input
                            // type="number"
                            id="savingPerMonth"
                            placeholder="10,000"
                            value={Number(commuInfo.savingPerMonth).toLocaleString('en-US')}
                            onChange={(e) => handleUpdateFormNumber("savingPerMonth", e.target.value)}
                            className="px-4 py-2 border border-gray-300 rounded-md w-2/3 md:w-2/5"
                        />
                        <div className="font-medium">บาท/เดือน</div>
                    </div>
                </div>

                <div className="pt-6">
                    <div className="font-semibold pb-2">ประเภทที่ดิน (4 อันดับแรก เรียงจากมากไปน้อย)</div>
                    {Object.keys(commuInfo.fieldType).map((key, index) => (
                        <div key={index} className="flex items-center space-x-2 py-1">
                            <div className="pt-1">อันดับที่ {index + 1} </div>
                            <select
                                value={(commuInfo.fieldType as any)[key]}
                                onChange={(e) => handleFieldType(e, key)}
                                className="px-4 py-2 border border-gray-300 rounded-md w-2/3 md:w-2/5"
                            >
                                <option value="">กรุณาเลือก</option>
                                {fieldTypeOptions.map((option) => (
                                    <option key={option} value={option}>
                                        {option}
                                    </option>
                                ))}
                            </select>
                        </div>
                    ))}
                </div>

                <div className="pt-6">
                    <div className="font-semibold pb-2">ประเภทกรรมสิทธิ์ (4 อันดับแรก เรียงจากมากไปน้อย)</div>
                    {Object.keys(commuInfo.ownerType).map((key, index) => (
                        <div key={index} className="flex items-center space-x-2 py-1">
                            <div className="pt-1">อันดับที่ {index + 1} </div>
                            <select
                                value={(commuInfo.ownerType as any)[key]}
                                onChange={(e) => handleOwnerType(e, key)}
                                className="px-4 py-2 border border-gray-300 rounded-md w-2/3 md:w-2/5"
                            >
                                <option value="">กรุณาเลือก</option>
                                {ownerTypeOptions.map((option) => (
                                    <option key={option} value={option}>
                                        {option}
                                    </option>
                                ))}
                            </select>
                        </div>
                    ))}
                </div>

            </div>
        </div>
    )
};

export default EditCommuInfo;