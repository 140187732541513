import React, { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import { api } from "../api";
import useManagementStore from "../stores/communityInformation";
import { CommunityInformationRawData } from "../types/apiCommuInfo";

const dateTimeFormat = (dateTimeString: string): string => {
    const dateTime = new Date(dateTimeString);
    dateTime.setHours(dateTime.getHours());
    const day = dateTime.getDate().toString().padStart(2, "0");
    const month = (dateTime.getMonth() + 1).toString().padStart(2, "0");
    const year = (dateTime.getFullYear() + 543).toString();
    const hours = dateTime.getHours().toString().padStart(2, "0");
    const minutes = dateTime.getMinutes().toString().padStart(2, "0");
    const seconds = dateTime.getSeconds().toString().padStart(2, "0");
    return `${day}/${month}/${year} ${hours}:${minutes}:${seconds}`;
};

const HomePage: React.FC = () => {

    const navigate = useNavigate();
    let { setCommuInfo, setStatusInfo } = useManagementStore((state) => state);
    const [searchText, setSearchText] = useState("");
    const [data, setData] = useState<CommunityInformationRawData[]>([]);
    const { data: response, isLoading, isFetched, error, isSuccess, refetch } = useQuery('commuInfo', api.commuInfo.fetchCommuList, {
    })

    function searchAndOrderByCommName(data: CommunityInformationRawData[], searchText: string) {
        const lowerSearchText = searchText.toLowerCase();
        let matchedData: CommunityInformationRawData[] = [];
        let remainingData: CommunityInformationRawData[] = [];
        data.forEach(obj => {
            const commuName = obj.commu_name.toLowerCase();
            if (commuName.includes(lowerSearchText)) {
                matchedData.push(obj);
            } else {
                remainingData.push(obj);
            }
        });

        matchedData.sort((a, b) => {
            const nameA = a.commu_name.toLowerCase();
            const nameB = b.commu_name.toLowerCase();

            if (nameA < nameB) return -1;
            if (nameA > nameB) return 1;
            return 0;
        });

        return matchedData.concat(remainingData);
    }

    const [isSearching, setIsSearching] = useState<boolean>(false)

    const handleSearch = () => {
        if (searchText!) {
            const filteredData = searchAndOrderByCommName(data, searchText)
            setData(filteredData);
            setIsSearching(true)
        }
    };

    const handleClearSearch = () => {
        setIsSearching(false)
        refetch().then(() => {
            setData(response!.data)
            setSearchText("")
        })
    }

    const clickedWatchSomeData = (data: CommunityInformationRawData) => {
        // console.log(data.id)
        // setCommuInfo(data.community_information.community_info)
        // setStatusInfo(data.community_information.status_info)
        navigate(`/edit?id=${data.id}`)
    }

    const clickedAddData = () => {
        navigate(`/create`)
    }

    useEffect(() => {
        if (isFetched && response!) {
            setData(response.data)
        }
    }, [response, isFetched])

    return (
        <div about="main" className="flex flex-col items-center min-h-screen h-auto pb-20">
            <div about="header-of-home-page" className="flex flex-col items-center pt-40 min-w-[100vw]">
                <div about="header" className="text-2xl font-bold">รายชื่อชุมชน</div>
                <div about="search-components" className="flex pt-8 w-auto min-w-screen">
                    <input
                        type="text"
                        className="pl-3 font-light border border-gray-300 py-2 rounded-md focus:outline-none sm:w-[50vw] md:w-[50vw] lg:w-[25vw]"
                        placeholder="ค้นหาจากชื่อชุมชน"
                        value={searchText}
                        onChange={(e) => setSearchText(e.target.value)}
                    />
                    <button
                        className="bg-blue-500 hover:bg-blue-700 text-white py-2 rounded-md ml-2 w-auto px-[1.5vw]"
                        onClick={handleSearch}
                    >
                        ค้นหา
                    </button>
                    <button
                        className="bg-blue-500 hover:bg-blue-700 text-white py-2 rounded-md ml-2 w-auto px-[1.5vw]"
                        onClick={clickedAddData}
                    >
                        + เพิ่มชุมชน
                    </button>
                </div>
            </div>

            <div className="relative max-h-[90vh] overflow-y-auto z-0 mt-5">
                <div className="flex min-w-screen h-10">
                    {
                        isSearching
                            ? <div className="flex items-center">
                                <p className="font-semibold">ผลลัพธ์การค้นหา</p>
                                <button className="font-semibold ml-4 text-red-500 underline"
                                    onClick={handleClearSearch}
                                >ล้าง</button>
                            </div>
                            : <div className=""></div>
                    }
                </div>

                <table className="w-full relative">
                    <thead className="sticky top-0 z-10 bg-white ">
                        <tr>
                            <th className="px-4 py-2 text-gray-900 text-xl font-semibold">ชื่อชุมชน</th>
                            <th className="px-4 py-2 text-gray-900 text-xl font-semibold">แก้ไขล่าสุด</th>
                            <th className="px-4 py-2"></th>
                        </tr>
                    </thead>
                    <tbody className="z-0">
                        {
                            data.length > 0 && data.map((item: CommunityInformationRawData, index: number) => (
                                <tr key={item.id} className={index % 2 === 0 ? `bg-white` : `bg-slate-50 shadow`}>
                                    <td className="px-8 py-2 font-normal">
                                        <p className="line-clamp-2">{item.community_information.community_info.commuName}</p>
                                    </td>
                                    <td className="px-8 py-2 font-normal">{dateTimeFormat(item.updated_date!.toString())}</td>
                                    <td className="px-8 py-2">
                                        <button
                                            className="px-8 py-2 rounded-md shadow border hover:bg-blue-700 hover:text-white border-blue-500 flex justify-center items-center"
                                            onClick={(e) => clickedWatchSomeData(item)}
                                        >
                                            ดู
                                        </button>
                                    </td>
                                </tr>
                            ))
                        }
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default HomePage;
