import React, { useEffect, useState } from 'react';
import statusKey from '../configs/statusKey';
import { StatusInfo } from '../models';
import useManagementStore from '../stores/communityInformation';
import { allValueOfKeyAreTrue } from '../utils';

const Following: React.FC = () => {
    let statusInfo = useManagementStore((state) => state.statusInfo);
    let setStatusInfo = useManagementStore((state) => state.setStatusInfo);
    let isViewMode = useManagementStore((state) => state.isViewMode);
    
    const [keyOfThisPage, setKeyOfThisPage] = useState<string[]>(Object.keys(statusInfo.moving.following))
    const [subKey, setSubKey] = useState<string[]>(keyOfThisPage)

    const previousFormIsDone = (): boolean => {
        let temp = !JSON.stringify(statusInfo.movingUp.preparation).includes('false')
        return temp
    }
    const handleWith3Month = (parentKey: string, secondaryParentKey: string, childrenKey: string) => {
        (((statusInfo.moving.following as any)[parentKey] as any)[secondaryParentKey] as any)[childrenKey] = !(((statusInfo.moving.following as any)[parentKey] as any)[secondaryParentKey] as any)[childrenKey]
        let followingNotDone = JSON.stringify(statusInfo.moving.following).includes('false')
        if (followingNotDone) {
            statusInfo.moving.improvement = JSON.parse(JSON.stringify(statusInfo.moving.improvement).replaceAll('true', 'false'))
        }
        setStatusInfo(statusInfo)
    }

    useEffect(() => {
    }, [])

    return (
        <div className='bg-grey-50 p-3'>
            <div>{`${statusKey.following}`}</div>
            {keyOfThisPage.map((subKey) => (
                <div key={subKey} className='container mx-auto p-3 rounded bg-slate-100 mt-2'>
                    <div className='p-2'>{(statusKey as any)[subKey]}</div>
                    {
                        Object.keys((statusInfo.moving.following as any)[subKey]).map((subTopic) => (
                            <div key={`statusInfo.moving.following.${subTopic}`} className='flex p-2 rounded bg-slate-200 grid grid-cols-3 mb-2'>
                                <div className='grow p-1 col-span-1 pl-2'>{(statusKey as any)[subTopic]}</div>
                                <div className='grow py-1 pr-2 col-span-2'>
                                    {Object.keys(((statusInfo.moving.following as any)[subKey] as any)[subTopic]).map((key) => (
                                        <div className='flex grow justify-between pb-1 mb-1' key={key}>
                                            <label>{(statusKey as any)[key]}</label>
                                            <input
                                                type="checkbox"
                                                checked={((statusInfo.moving.following as any)[subKey] as any)[subTopic][key]}
                                                onChange={() => handleWith3Month(subKey, subTopic, key)}
                                                className="form-checkbox ml-2 w-[3.618vw]"
                                                disabled={!previousFormIsDone() || isViewMode}

                                            />
                                        </div>
                                    ))}
                                    <div className='flex grow bg-slate-300 py-1 justify-between rounded grid grid-cols-4'>
                                        <div className='pl-1 col-span-2'>{statusKey.did}</div>
                                        <div className='pl-1 col-span-1 flex justify-end'>=</div>
                                        <div className='mr-1 col-span-1 flex justify-end'>
                                            {allValueOfKeyAreTrue(((statusInfo.moving.following as any)[subKey] as any)[subTopic]) ? 1 : 0}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))
                    }

                </div>
            ))}
        </div>
    );
};

export default Following;
